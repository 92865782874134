import CategoriesPostModel from '../categories/models/categories-post.model';
import imageInput from '@/mixins/image-input.js';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new CategoriesPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("categories/setItem", formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                }
            });
        }
    },
    mixins: [imageInput],
}
